import React, { useState } from 'react'
import IndexLayout from 'site/src/components/org.layout/layout.component'

import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { graphql, useStaticQuery } from 'gatsby'
import {
  MobileContainer,
  ImageUnitMobile,
  LogoSerdil,
  Differentials,
  Exams,
  InfoTopPage,
  LogoSerdilTablet,
  TabletContainer,
  MainContainer,
  TopicsContainer,
  InfoContato1,
  InfoContato2,
  VerticalSeparator,
  Text
} from './styled'
import InfoContato from '@root/src/components/InfoContato'
import Accordion from 'site/src/components/Accordion'
import Title from 'site/src/components/Title'
import { Container, Grid, useTheme, useMediaQuery } from '@material-ui/core'

export default props => {
  const theme = useTheme()
  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  const { metadata } = props.data.cosmicjsLandingPages

  const informacoesContato = props.data.informacoesContato.metadata

  const [expanded, setExpanded] = useState(false)
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    process.env.GATSBY_MARCA === 'WEINMANN' && (
      <IndexLayout location={props.location}>
        <Container maxWidth="md">
          <TitleWithBreadcrumbRow />

          <MobileContainer>
            <ImageUnitMobile>
              <img src={metadata?.banner_serdil.url}></img>
            </ImageUnitMobile>

            <LogoSerdil>
              <img src={metadata?.logo_serdil.url}></img>
            </LogoSerdil>

            <Text>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: metadata?.sobre_a_marca
                }}
              />
            </Text>
          </MobileContainer>

          <MainContainer>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <LogoSerdilTablet>
                  <img src={metadata?.logo_serdil.url}></img>
                </LogoSerdilTablet>
              </Grid>

              <TabletContainer>
                <Grid className="description-serdil" item md={6}>
                  <Text>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: metadata?.sobre_a_marca
                      }}
                    />
                  </Text>
                </Grid>
                <Grid className="banner-serdil" item md={6}>
                  <img src={metadata?.banner_serdil.url}></img>
                </Grid>
              </TabletContainer>

              <Differentials>
                <Title className="newLayout">Diferenciais</Title>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TopicsContainer>
                      <ul>
                        {metadata?.diferenciais.topicos
                          .slice(0, metadata?.diferenciais.topicos.length / 2)
                          .map(topico => (
                            <li>{topico.diferencial}</li>
                          ))}
                      </ul>
                    </TopicsContainer>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TopicsContainer className="last">
                      <ul>
                        {metadata?.diferenciais.topicos
                          .slice(metadata?.diferenciais.topicos.length / 2)
                          .map(topico => (
                            <li>{topico.diferencial}</li>
                          ))}
                      </ul>
                    </TopicsContainer>
                  </Grid>
                </Grid>
              </Differentials>

              <Exams>
                <Title className="newLayout">Exames e vacinas</Title>
                {metadata?.exames_oferecidos.map(({ titulo, descricao }, index) => {
                  return (
                    <Accordion
                      type="medical-area"
                      bgWhite
                      key={index}
                      slug={index.toString()}
                      title={titulo}
                      expanded={expanded}
                      handleChange={handleChange}
                    >
                      <InfoTopPage variant="body1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: descricao
                          }}
                        ></div>
                      </InfoTopPage>
                    </Accordion>
                  )
                })}
              </Exams>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <InfoContato1>
                    <Title noMargin noLine>
                      Horários de coleta dos exames
                    </Title>
                    <Text>{metadata?.horarios_de_coleta_dos_exames}</Text>
                    <a>{metadata?.endereco}</a>
                  </InfoContato1>
                </Grid>
                {!downOfSm && (
                  <Grid item sm={1}>
                    <VerticalSeparator />
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <InfoContato2>
                    <InfoContato
                      informacoesContato={informacoesContato}
                      subtitulo={'Para agendar, é só falar com a gente no telefone'}
                      titulo={'Agendamento'}
                      horarios={metadata?.agendamento.horario_de_agendamento}
                    />
                  </InfoContato2>
                </Grid>
              </Grid>
            </Grid>
          </MainContainer>
        </Container>
      </IndexLayout>
    )
  )
}

export const query = graphql`
  query SobreSerdil {
    informacoesContato: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
      metadata {
        telefoneContato
        chatOnlineLink
        agendamentoWhatsappLink
        telefoneContatoMedico
        label_telefone
        label_whatsapp
        chatOnlineLinkMedico
        agendamentoWhatsappLinkMedico
        texto_fale_conosco
        show_whatsapp_contactus
      }
    }

    cosmicjsLandingPages(slug: { eq: "sobre-serdil" }) {
      id
      metadata {
        banner_serdil {
          url
        }
        logo_serdil {
          url
        }
        sobre_a_marca
        diferenciais {
          topicos {
            diferencial
          }
        }
        exames_oferecidos {
          titulo
          descricao
        }
        horarios_de_coleta_dos_exames
        endereco
        agendamento {
          text
          icon {
            url
          }
          icon_telefone {
            url
          }
          telefone_de_contato
          icon_whats {
            url
          }
          whatsapp
          horario_de_agendamento
        }
      }
    }
  }
`

import styled from 'styled-components'

export const MobileContainer = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

export const ImageUnitMobile = styled.div`
  margin: 100px 0 20px 0;

  img {
    width: 100%;
  }
`

export const LogoSerdil = styled.div`
  img {
    display: flex;
    margin: 0 auto 20px auto;
  }
`
export const MainContainer = styled.div`
  @media (min-width: 767px) {
    margin-top: 0;

    .divider {
      border-right: 2px solid #67b8c1;
    }

    .container-schedule {
      margin: 30px 0;
      display: flex;
      align-items: center;
    }
  }

`
export const LogoSerdilTablet = styled.div`
  display: none;

  @media (min-width: 767px) {
    display: flex;
    margin-bottom: 50px;
  }
`

export const TabletContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    grid-gap: 30px;

    .description-serdil{
      width:100%;
    }

    .banner-serdil {
      display: flex;
      align-self: center;
      width:100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`

export const Differentials = styled.div`
  margin: 20px 0;
`

export const Exams = styled.div`
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`
export const CollectionTime = styled.div`
  h3 {
    font-size: 24px;
  }
`

export const SchedulingContainer = styled.div`
  h3 {
    font-size: 24px;
  }
`
export const InfoTopPageContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  padding-left: 20px;
`

export const InfoTopPageContactTitle = styled.div`
  font-family: Signika;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #373a3a;
`
export const ContactContainer = styled.div`
  display: flex;
  margin: 10px 0;
  font-family: Signika;
`

export const WhatsappPhoneContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
`

export const LinkToContact = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  span {
    margin-left: 5px;
    text-decoration: underline;
  }
  p {
    margin-left: 5px;
    font-style: normal;

    font-size: 12px;
    line-height: 16px;
    color: #098a9c;
  }
`
export const InfoTopPage = styled.span`
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #444444 !important;

  p {
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #444444 !important;
  }

  a {
    text-decoration: underline;
    color: #098a9c;
  }
`
export const TopicsContainer = styled.div`
  ul {
    padding-left: 20px;

    @media (max-width: 425px) {
      margin: 0px;
    }

    li {
      font-family: Signika;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      margin-bottom:24px;

      &.last{
        margin-bottom: 12px;
      }
    }
  }

  &.last {
    margin-bottom: 25px;
  }
`

export const VerticalSeparator = styled.div`
  margin: 36px;
  width: 0.28px;
  height: 188px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #b3dce0;
  border-radius: 3px;
`
export const InfoContato1 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  p {
    font-family: Signika;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #373a3a;
  }

  div {
    width: 100%;
  }
`
export const InfoContato2 = styled.div`
  margin: 20px;
  @media (max-width: 768px) {
    margin: 0;
  }
`

export const Text = styled.div`
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 25px;

  .description p{
    font-weight: normal!important;
    color: #666666!important;

  }
`
